.site-layout-background {
  height: 100vh;
}

.header {
  width: 100%;
}

.ant-layout-header {
  height: 64px;
  padding: 0px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 64px;
  background: #001529;
}

.logo {
  text-align: center;
}

.iconSider {
  width: 20px;
  color: aliceblue;
}

.name-account {
  color: white;
  margin-right: 7px;
}
