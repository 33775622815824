.container {
  /* border: 9px solid black; */
  width: 800px;
  background-color: white;
  /* text-align: center; */
}

.header-print,
.content-print{
  background-color: white;
  /* text-align: center; */
}

.footer-print{
    background-color: white;
    margin-left: 500px;
    text-align: center;
}

.prescription {
  font-size: 30px;
 margin-left: 250px;
}

@media print {
  .hide-on-print {
    display: none;
  }
  @page {
    margin: 0;
  }
  body {
    margin: 1.6cm;
  }
}
